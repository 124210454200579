import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    translate: "MENU.DASHBOARD",
    type: "item",
    icon: "home",
    url: "dashboard",
  },
  {
    id: "categories",
    title: "Categories",
    translate: "MENU.CATEGORIES",
    type: "item",
    icon: "file",
    url: "categories",
    // role: ["Admin"],
  },
  {
    id: "stores",
    title: "Stores",
    translate: "MENU.STORES",
    type: "item",
    icon: "shopping-bag",
    url: "stores",
    // role: ["Admin","SuperAdmin"],
  },
  {
    id: "distributors",
    title: "Supplier",
    translate: "MENU.DISTRIBUTORS",
    type: "item",
    icon: "truck",
    url: "distributors",
    // role: ["Admin","SuperAdmin"],
  },
  /* {
    id: "orders",
    title: "Orders",
    translate: "MENU.ORDERS",
    type: "item",
    icon: "shopping-cart",
    url: "orders",
    role: ["Admin"],
  }, */
];
