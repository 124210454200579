import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../environments/environment";

import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OWNCODESService {
  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _httpClient: HttpClient,
    private _toastrService: ToastrService
  ) {}

  setPreCacheData(key: string, data: any, minutes = 0): void {
    localStorage.setItem(key, data);
    localStorage.setItem(
      `${key}_expire`,
      `${this.addMinutesToCurrentDateTime(minutes)}`
    );
  }

  getPreCacheData(
    key: string,
    jsonParse = false
  ): string | number | object | any[] | null {
    let _cacheData = localStorage.getItem(key);
    let _cacheExpire = localStorage.getItem(`${key}_expire`);
    _cacheData = _cacheData && jsonParse ? JSON.parse(_cacheData) : _cacheData;
    if (_cacheExpire != "lifetime") {
      if (Number(_cacheExpire) > this.getCurrentDateTime()) {
        return _cacheData;
      } else {
        localStorage.removeItem(key);
        localStorage.removeItem(`${key}_expire`);
        return null;
      }
    } else {
      return _cacheData ? _cacheData : null;
    }
  }

  removePreCacheData(keys: object): void {
    for (const key in keys) {
      localStorage.removeItem(keys[key]);
      localStorage.removeItem(`${keys[key]}_expire`);
    }
  }

  resetPreCacheData(keys: object): void {
    for (const key in keys) {
      let _cacheExpire = localStorage.getItem(`${keys[key]}_expire`);
      if (_cacheExpire != "lifetime") {
        if (Number(_cacheExpire) < this.getCurrentDateTime()) {
          localStorage.removeItem(keys[key]);
          localStorage.removeItem(`${keys[key]}_expire`);
        }
      }
    }
  }

  /**
   * addMinutesToCurrentTime
   * return newTime
   */
  addMinutesToCurrentDateTime(minutes: number): number {
    const cd = new Date();
    const nd = new Date(cd.getTime() + minutes * 60000);
    let year = nd.getFullYear();
    let month = nd.getMonth() < 10 ? `0${nd.getMonth()}` : nd.getMonth();
    let date = nd.getDate() < 10 ? `0${nd.getDate()}` : nd.getDate();
    let hours = nd.getHours() < 10 ? `0${nd.getHours()}` : nd.getHours();
    let minute = nd.getMinutes() < 10 ? `0${nd.getMinutes()}` : nd.getMinutes();
    let seconds =
      nd.getSeconds() < 10 ? `0${nd.getSeconds()}` : nd.getSeconds();
    return Number(`${year}${month}${date}${hours}${minute}${seconds}`);
  }
  /**
   * getCurentTime
   * return currentTime
   */
  getCurrentDateTime(): number {
    const cd = new Date();
    let year = cd.getFullYear();
    let month = cd.getMonth() < 10 ? `0${cd.getMonth()}` : cd.getMonth();
    let date = cd.getDate() < 10 ? `0${cd.getDate()}` : cd.getDate();
    let hours = cd.getHours() < 10 ? `0${cd.getHours()}` : cd.getHours();
    let minute = cd.getMinutes() < 10 ? `0${cd.getMinutes()}` : cd.getMinutes();
    let seconds =
      cd.getSeconds() < 10 ? `0${cd.getSeconds()}` : cd.getSeconds();
    return Number(`${year}${month}${date}${hours}${minute}${seconds}`);
  }
  /**
   * getCurentDateTime
   * return currentDateTime in sql format
   */
  getCurrentDateTimeSqlFormat(): string {
    const cd = new Date();
    let year = cd.getFullYear();
    let month = cd.getMonth() < 10 ? `0${cd.getMonth()}` : cd.getMonth();
    let date = cd.getDate() < 10 ? `0${cd.getDate()}` : cd.getDate();
    let hours = cd.getHours() < 10 ? `0${cd.getHours()}` : cd.getHours();
    let minute = cd.getMinutes() < 10 ? `0${cd.getMinutes()}` : cd.getMinutes();
    let seconds =
      cd.getSeconds() < 10 ? `0${cd.getSeconds()}` : cd.getSeconds();

    return `${year}-${month}-${date} ${hours}:${minute}:${seconds}`;
  }
  /**
   * getCurentDate
   * return currentDateTime in sql format
   */
  getCurrentDateSqlFormat(): string {
    const cd = new Date();
    let year = cd.getFullYear();
    let month = cd.getMonth() < 10 ? `0${cd.getMonth()}` : cd.getMonth();
    let date = cd.getDate() < 10 ? `0${cd.getDate()}` : cd.getDate();

    return `${year}-${month}-${date}`;
  }

  /**
   * @param endPoint The Api Request uel end path
   * @returns {any} An observable containing the employee data
   */
  get(endPoint: string): Observable<any> {
    return this._httpClient.get(`${environment.apiUrl}/${endPoint}`);
    /* .pipe(map((d) => this.getPagedData(d, page))) */
  }

  /**
   * @param endPoint The Api Request uel end path
   * @param data The post data object
   * @returns {any} An observable containing the employee data
   */
  post(endPoint: string, data: object): Observable<any> {
    return this._httpClient.post(`${environment.apiUrl}/${endPoint}`, {
      ...data,
    });
  }
  /**
   * @param endPoint The Api Request uel end path
   * @param data The post data object
   * @returns {any} An observable containing the employee data
   */
  delete(endPoint: string, data: object): Observable<any> {
    return this._httpClient.delete(`${environment.apiUrl}/${endPoint}`, {
      ...data,
    });
  }
  /**
   * @param endPoint The Api Request uel end path
   * @param data The post data object
   * @returns {any} An observable containing the employee data
   */
  phpPost(data: object): Observable<any> {
    return this._httpClient.post(`${environment.phpUrl}`, data);
  }

  public successToast(
    message: string,
    title?: string,
    duration?: number,
    closeButton?: boolean
  ): void {
    title = title || "Alert";
    closeButton = closeButton || true;
    let timeOut = duration || 3500;
    this._toastrService.success(message, title, {
      toastClass: "toast ngx-toastr",
      closeButton,
      timeOut,
      extendedTimeOut: 1500,
      progressBar: true,
      enableHtml: true,
    });
  }

  public errorToast(
    message: string,
    title?: string,
    duration?: number,
    closeButton?: boolean
  ): void {
    title = title || "Alert";
    closeButton = closeButton || true;
    let timeOut = duration || 3500;
    this._toastrService.error(message, title, {
      toastClass: "toast ngx-toastr",
      closeButton,
      timeOut,
      extendedTimeOut: 1500,
      progressBar: true,
      enableHtml: true,
    });
  }
}
