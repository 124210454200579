// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: "https://test-admin-backend.aladdininformatics.com/api",
  // apiUrl: "http://localhost:5011/api",
  phpUrl: "https://aladin.primewavetech.online/aladdin-test/api.php",
  // phpUrl: "http://php.local/api.php",
  secretKey: 'aladin@2022!',
  storage: {
    keys: {
      loggedInUser: "Aladdin_loggedInUser",
      categories: "Aladdin_categories",
      stores: "Aladdin_stores",
      distributors: "Aladdin_distributors",
      products: "Aladdin_products",
    },
    cache: {
      minutes: 10,
      day: 1440,
      lifetime: "lifetime",
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
