<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span>
          <img src="{{ coreConfig.app.appLogoImage }}" alt="{{ coreConfig.app.appName }}" height="60" />
        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- ? Language selection | Uncomment if needed-->
    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
        ><span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->
    <!--/ Language selection -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none" *ngIf="currentUser">
          <span class="user-name font-weight-bolder">{{
            currentUser.userName
            }}</span><span class="user-status">{{ currentUser.role }}</span>
        </div>
        <span class="avatar"><img class="round" src="../../../../assets/images/portrait/small/user.png" alt="avatar"
            height="40" width="40" /><span class="avatar-status-online"></span></span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <a ngbDropdownItem [routerLink]="['/']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        > -->
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem style="font-size: 13px;" (click)="modalOpenSLCIM(changepassword)"><span [data-feather]="'settings'"
            [class]="'mr-50'"></span> Change
          Password</a>
        <a ngbDropdownItem style="font-size: 13px;" (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>

<ng-template #changepassword let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel160">Change Password</h4>
    <button type="button" class="close" (click)="closeChangePasswordModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>

    <form class="form" [formGroup]="changepasswordForm" (ngSubmit)="onSubmit()" *blockUI="'update-form'">
      <div class="col-12">
        <div class="form-group">
          <div class="input-group input-group-merge form-password-toggle">
            <input class="input-group" placeholder="New Password *" pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,20}$" [type]="passwordTextType ? 'text' : 'password'" name="password" class="form-control" formControlName="password" required/>
            <div class="input-group-append">
              <span class="input-group-text cursor-pointer"
                ><i
                  class="feather font-small-4"
                  [ngClass]="{
                    'icon-eye-off': passwordTextType,
                    'icon-eye': !passwordTextType
                  }"
                  (click)="togglePasswordTextType()"
                ></i
              ></span>
            </div>
          </div>
          <div *ngIf="submitted && f['password'].errors" class="form-text text-danger">
            <small *ngIf="f['password'].errors.required">Password is required.</small> 
            <small *ngIf="f['password'].errors.pattern">Password should have a minimum of 8 and maximum of 20 characters. Must contain at least one letter, one number, and a special character from !@#$%^&*()_+</small>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group input-group-merge form-password-toggle">
            <input class="input-group" placeholder="Confirm Password *" pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,20}$" [type]="changepasswordTextType ? 'text' : 'password'" name="confirmPassword" class="form-control" formControlName="confirmPassword" required/>
            <div class="input-group-append">
              <span class="input-group-text cursor-pointer"
                ><i
                  class="feather font-small-4"
                  [ngClass]="{
                    'icon-eye-off': changepasswordTextType,
                    'icon-eye': !changepasswordTextType
                  }"
                  (click)="toggleChangePasswordTextType()"
                ></i
              ></span>
            </div>
          </div>
          <div *ngIf="submitted && f['confirmPassword'].errors" class="form-text text-danger">
            <small *ngIf="f['confirmPassword'].errors.required">Password is required.</small>
            <small *ngIf="f['confirmPassword'].errors.pattern">Password should have a minimum of 8 and maximum of 20 characters. Must contain at least one letter, one number, and a special character from !@#$%^&*()_+</small>
          </div>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSubmit()" rippleEffect>
      Submit
    </button>
  </div>
</ng-template>