import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { TranslateModule } from "@ngx-translate/core";

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { ErrorInterceptor, JwtInterceptor } from "app/auth/helpers";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { BlockUIModule } from 'ng-block-ui';
import { MessageService } from "./main/services/message.service";

const appRoutes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./main/authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./main/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "categories",
    loadChildren: () =>
      import("./main/category/category.module").then((m) => m.CategoryModule),
  },
  {
    path: "stores",
    loadChildren: () =>
      import("./main/store/store.module").then((m) => m.StoreModule),
  },
  {
    path: "distributors",
    loadChildren: () =>
      import("./main/distributor/distributor.module").then(
        (m) => m.DistributorModule
      ),
  },
  {
    path: "orders",
    loadChildren: () =>
      import("./main/order/order.module").then((m) => m.OrderModule),
  },
  {
    path: "products",
    loadChildren: () =>
      import("./main/product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "sales",
    loadChildren: () =>
      import("./main/reports/sales.module").then((m) => m.SalesModule),
  },
  // {
  //   path: "storecheck",
  //   loadChildren: () =>
  //     import("./main/storecheck/storecheck.module").then((m) => m.StorecheckModule),
  // },
  {
    path: "store-check",
    loadChildren: () =>
      import("./main/store-check/store-check.module").then((m) => m.StoreCheckModule),
  },
  {
    path: "configuration",
    loadChildren: () =>
      import("./main/configuration/configuration.module").then((m) => m.ConfigurationModule),
  },
  {
    path: "misc",
    loadChildren: () =>
      import("./main/miscellaneous/miscellaneous.module").then(
        (m) => m.MiscellaneousModule
      ),
  },
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/misc/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
      useHash: true,
    }),
    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    BlockUIModule.forRoot(),

    // App modules
    LayoutModule,
  ],
  providers: [
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
