import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NzImageModule } from "ng-zorro-antd/image";

import { CoreImagePreviewComponent } from "@core/components/core-image-preview/core-image-preview.component";

@NgModule({
  declarations: [CoreImagePreviewComponent],
  imports: [CommonModule, NzImageModule],
  exports: [CoreImagePreviewComponent],
})
export class CoreImagePreviewModule {}
