export const locale = {
  lang: "en",
  data: {
    MENU: {
      DASHBOARD: "Dashboard",
      CATEGORIES: "Categories",
      STORES: "Stores",
      DISTRIBUTORS: "Distributors",
      ORDERS: "Orders",
    },
  },
};
