import {
  Component,
  OnDestroy,
  OnInit,
  HostBinding,
  HostListener,
  ViewEncapsulation,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";

import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

import { AuthenticationService } from "app/auth/service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";

import { User } from "app/auth/models";

import { coreConfig } from "app/app-config";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { OWNCODESService } from "@core/services/owncodes.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;
  changepasswordForm: FormGroup;
  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  @HostBinding("class.fixed-top")
  public isFixed = false;

  @HostBinding("class.navbar-static-style-on-scroll")
  public windowScrolled = false;
  updateModalRef: any;
  time: number;
  interval: NodeJS.Timeout;
  public passwordTextType: boolean;
  public changepasswordTextType: boolean;
  submitted: boolean;
  message: string;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == "navbar-static-top" &&
      this.coreConfig.layout.type == "horizontal"
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    private modalService: NgbModal,
    private formbuilder: FormBuilder,
    private _ocService: OWNCODESService,
  ) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

    this.languageOptions = {
      en: {
        title: "English",
        flag: "us",
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig(
      { app: { appLanguage: language } },
      { emitEvent: true }
    );
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem("prevSkin");

    if (this.currentSkin === "dark") {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem("prevSkin", this.currentSkin);
      this._coreConfigService.setConfig(
        { layout: { skin: "dark" } },
        { emitEvent: true }
      );
    }
  }
  initChangePasswordForm() {
    this.changepasswordForm = this.formbuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    });
  }

  passwordMatchingValidatior() {
    return this.changepasswordForm.get('password').value === this.changepasswordForm.get('confirmPassword').value ? { notmatched: false } : { notmatched: true };
  }
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }
  toggleChangePasswordTextType() {
    this.changepasswordTextType = !this.changepasswordTextType;
  }
  get f() { return this.changepasswordForm.controls; }

  @BlockUI('update-form') formBlockUI: NgBlockUI;


  // modal Open Srolling Long Content Inside
  modalOpenSLCIM(modalSLCIM) {
    this.startTimer();
    this.updateModalRef = this.modalService.open(modalSLCIM, { scrollable: true ,keyboard: false, backdrop: 'static', });
  }
  startTimer() {
    this.time = 0;
    this.interval = setInterval(() => {
      this.time++;
    }, 1000);
  }

  onSubmit() {
    this.submitted = true;
    let match = this.passwordMatchingValidatior();
    if (this.changepasswordForm.valid) {
      if (match.notmatched !== true) {
        this.formBlockUI.start('Updating...');
        this._authenticationService.changepassword(this.currentUser.id,this.changepasswordForm.value.password).subscribe(res => {
          this.changepasswordForm.reset();
          this._ocService.successToast('Password Changed.', 'Success');
          this.submitted = false;
          this.formBlockUI.stop();
          this.closeChangePasswordModal();
          this.logout();
        }, err => {
          this.formBlockUI.stop();
          if (err && err['data']) {
            this._ocService.errorToast(err['data']['message'], 'Error');
            this.formBlockUI.stop();
          } else {
            this._ocService.errorToast(err['data']['message'], 'Error');
            this.formBlockUI.stop();
          }
        });
      } else {
        this._ocService.errorToast("New Password and Confirm Password should be the same", 'Error');
        this.formBlockUI.stop();
      }

    }
  }


  closeChangePasswordModal() {
    this.modalService.dismissAll();
    this.changepasswordForm.reset();
    this.submitted = false;
    // this.updateModalRef.dismiss('Closed');
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
    this._router.navigate(["/auth/login"]);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // get the currentUser details from localStorage
    this.initChangePasswordForm();
    this.currentUser = this._authenticationService.currentUserValue;
    console.log("this.currentUser",this.currentUser)
    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === "horizontal";
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === "vertical") {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === "fixed-top") {
              this.isFixed = true;
            }
          }, 0);
        }
      });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == "horizontal") {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive("bs-gt-xl");
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang,
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
