import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ApiResponseError } from "app/model/apiResponseError";
import { OWNCODESService } from "@core/services/owncodes.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  private localStorageKey = environment.storage.keys.loggedInUser;

  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _ocService: OWNCODESService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      this._ocService.getPreCacheData(this.localStorageKey, true) as User
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    debugger;
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    debugger;
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Client
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/userLogin`, {
        email,
        password,
      })
      .pipe(
        map((response) => {
          let user = null;
          // console.log("LoginResponse---------", response);
          // login successful if there's a jwt token in the response
          if (
            response.status == "SUCCESS" &&
            response.data &&
            response.data.details
          ) {
            user = response.data.details;
            user = {
              id: user.id,
              email: email,
              userName: user.userName,
              avatar: null,
              role: user.rolename,
              token: user.token,
              superAdmin: user.superAdmin,
              rights: user.rolesAccessed
            };
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this._ocService.setPreCacheData(
              this.localStorageKey,
              JSON.stringify(user),
              environment.storage.cache.day
            );

            this._ocService.successToast(
              `You have successfully logged in as an ${user.role} user. Now you can start to explore. Enjoy! 🎉`,
              `👋 Welcome, ${user.userName}!`
            );

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }

  getLoggedInUserData() {
    return JSON.parse(localStorage.getItem('Aladdin_loggedInUser'));
  }

  changepassword(id, password: string) {
    return this._ocService.post('user/changepassword', { id, password });
  }

  /**
   * User logout
   *
   */
  logout() {
    // notify
    this.currentUserSubject.next(null);
    // remove user from local storage to log user out
    this._ocService.removePreCacheData(environment.storage.keys);
  }

  /**
   *
   * @params message: string the messsage for toast
   * @params title: string the title for toast
   * @params (Optional) duration: number Define how long you want this toast to show (1000 = 1 second)
   */
   successToast(message: string, title?: string, duration?: number) {
    if (duration && Number(duration) > 999) {
      this._ocService.successToast(message, title, duration);
    } else {
      this._ocService.successToast(message, title);
    }
  }

  /**
   *
   * @params message: string the messsage for toast
   * @params title: string the title for toast
   * @params (Optional) duration: number Define how long you want this toast to show (1000 = 1 second)
   */
  errorToast(message?: string, title?: string, duration?: number) {
    message =
      message || "Please check console or contact IT Team for more info.";
    if (duration && Number(duration) > 999) {
      this._ocService.errorToast(message, title, duration);
    } else {
      this._ocService.errorToast(message, title);
    }
  }

  /**
   * Habdle api errors
   * @params error: ApiResponseError
   */
  handleError(error: ApiResponseError, type: string) {
    this.errorToast(error.data.message, error.status);
  }
}
