import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private message$ = new Subject<string>();

  constructor() { }

  setMessage(message: string){
    this.message$.next(message);
  }

  getMesssage(){
    return this.message$.asObservable();
  }
}
