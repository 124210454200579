import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthenticationService } from "app/auth/service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;

    let userData = JSON.parse(localStorage.getItem("Aladdin_loggedInUser"));

    let dashboard = userData.rights.filter(right => right.entity.name == 'dashboard')[0];
    let distributor = userData.rights.filter(right => right.entity.name == 'distributors')[0];
    let categories = userData.rights.filter(right => right.entity.name == 'categories')[0];
    let products = userData.rights.filter(right => right.entity.name == 'products')[0];
    let reports = userData.rights.filter(right => right.entity.name == 'reports')[0];
    let storecheck = userData.rights.filter(right => right.entity.name == 'storecheck')[0];
    let configurations = userData.rights.filter(right => right.entity.name == 'configurations')[0];
    let orders = userData.rights.filter(right => right.entity.name == 'orders')[0];
    let stores = userData.rights.filter(right => right.entity.name == 'stores')[0];

    if (currentUser) {
      // check if route is restricted by role
      if (
        route.data.roles &&
        route.data.roles.indexOf(currentUser.role) === -1
      ) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "StoreListComponent" && stores.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "CategoryListComponent" && categories.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "DistributorListComponent" && distributor.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "ProductListComponent" && products.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "SalesListComponent" && reports.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "StoreCheckComponent" && storecheck.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "ConfigurationComponent" && configurations.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "OrderListComponent" && orders.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }
      if (route.data.animation == "DashboardComponent" && dashboard.view != true) {
        // role not authorised so redirect to not-authorized page
        this._router.navigate(["/misc/not-authorized"]);
        // this._authenticationService.errorToast('Selling Price should not be 0', 'Error');
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(["/auth/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
