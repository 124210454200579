import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { ApiResponseError } from "app/model/apiResponseError";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthenticationService } from "app/auth/service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          if (!request.url.endsWith("userLogin")) {
            this._authenticationService.errorToast('You are not authorized to perform this action ', 'Error');
            // this._router.navigate(["/misc/not-authorized"]);
          }

          // ? Can also logout and reload if needed
          // this._authenticationService.logout();
          // location.reload(true);
        }
        // throwError
        let error: ApiResponseError = err.error;
        error.statusCode = err.status;
        error.status =
          error.status || (err.status != 200 && err.status != 201)
            ? "FAILED"
            : "SUCCESS";
        error.data = error.data || { message: null };
        error.data.message = error.data.message || err.statusText;

        return throwError(error);
      })
    );
  }
}
