import { Component, Input } from "@angular/core";

@Component({
  selector: "core-image-preview",
  styleUrls: ["./core-image-preview.component.scss"],
  template: `
    <img
      nz-image
      class="img-thumbnail"
      [class.cursor]="imageSrc && imageSrc.length > 5"
      [style.maxHeight]="maxHeight ? maxHeight : 'auto'"
      [style.maxWidth]="maxWidth ? maxWidth : 'auto'"
      [nzSrc]="imageSrc && imageSrc.length > 5 ? imageSrc : ''"
      [nzFallback]="defaultImg"
      alt=""
    />
  `,
})
export class CoreImagePreviewComponent {
  @Input("imageSrc") imageSrc: string;
  @Input("maxHeight") maxHeight: number;
  @Input("maxWidth") maxWidth: number;
  @Input("defaultImg") defaultImg =
    "./../../../assets/images/default-image.png";
}
